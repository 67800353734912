import React from "react";
import profile from '../../assets/profile.jpg';
import './About.css';

export default class About extends React.Component {
    render() {
        return (
            <div className="about" id="about">
                <img src={profile} className="profile" alt="profile" />
                <p className="about-text col-md-8 mb-5">Welcome to my website. I grew up in Seattle, attended Santa Clara University as a Senior Computer Science major and Entrepreneurship minor, and currently live in San Francisco as an AMTS SWE at Salesforce. Over the past couple years, I have gained an extensive knowledge of NodeJS, C++, Java, Object-Oriented Programming and Data Structures, as well as developed numerous related projects, just like this website! I started programming in middle school and then never stopped, always looking for a new project to dive into or a new concept to explore. </p>
            </div>
        );
    }
}