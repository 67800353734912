import React from "react";
import './Header.css';

export default class Header extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 800) {
          document.querySelector(".nav-bar").className = "nav-bar scroll";
        } else {
          document.querySelector(".nav-bar").className = "nav-bar";
        }
    };

    render() {
        return (
            <div className="nav-bar">
                <div class="logo"><a href="/"><h1>MITCH HANSEN</h1></a></div>
                <ul class="ul-items">
                    <li><a href="#about">About</a></li>
                    <li><a href="#experiences">Experience</a></li>
                    <li><a href="#contact">Contact</a></li>
                    <li><a href="./Hansen Mitch Resume.pdf" target="blank_">Resume</a></li>
                </ul>
            </div>
        );
    }
}