import React from "react";
import logo from '../../assets/dice-twenty-faces-twenty.svg';
import arrows from '../../assets/double-down-50.png';
import './Landing.css';

export default class Landing extends React.Component {
    scrollTo(e) {
        e.preventDefault();
        document.getElementById("about").scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
    
    render() {
        return (
            <div className="wrapper">
                <div className="welcome">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p className="welcome-text">
                        Roll For Initiative!
                    </p>
                </div>
                <div className="arrows">
                    <button onClick={this.scrollTo}>
                        <img src={arrows} className="arrows" alt="arrows" />
                    </button>
                </div>
            </div>
        );
    }
}