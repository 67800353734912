import React from 'react';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Experience from './components/Experience/Experience';
import Header from './components/Header/Header';
import Landing from './components/Landing/Landing';
import Particles from 'react-tsparticles';
import particlesConfig from './config/configParticles';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const particlesInit = (main) => {
    console.log(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="App">
      <Header />
      <Landing />
      <Particles
        init={particlesInit}
        loaded={particlesLoaded}
        canvasClassName="particles-canvas"
        style={{width: "100vw", height: "100vh", pointerEvents: "initial"}}
        height="100vh"
        width="100vw"
        options={particlesConfig}
      />
      <About />
      <Experience />
      <Contact />
    </div>
  );
}

export default App;
