import React from "react";
import './Experience.css';

export default class Experience extends React.Component {
    render() {
        return (
            <div id="experiences" className="exp-container">
                <h2 className="mt-5 mb-5">Experience</h2>
                <h3>Education</h3>
                <div className="experiences">
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>Santa Clara University</h4>
                            <p className="experience-period">
                                Sept 2017 - June 2021
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">Computer Science and Entrepreneurship Undergraduate</strong>
                                <span className="p-2 hidden-phone para">
                                    Coursework: Object Oriented Programming; Data Structures; Algorithms; Programming Languages; Web Development; Discrete Mathematics; Probability/Statistics; Computer Networks; Embedded Systems; Software Development; New Product Development. Activities: SCU Association of Computing Machinery Organization (SCU ACM); Sigma Chi Fraternity Alumni Relations and Tech Chair; Mathematical Association of America. GPA: 3.2
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                    <span className="glyphicon glyphicon-map-marker pr-2" />
                                        Santa Clara, CA
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                <h3>Career</h3>
                <div className="experiences">
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>Salesforce</h4>
                            <p className="experience-period">
                                Sept 2021 - Current
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">AMTS Software Engineer</strong>
                                <span className="p-2 hidden-phone para">
                                    Currently working on the Custom Schema team.
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                        <span className="glyphicon glyphicon-map-marker pr-2" />
                                        San Francisco, CA
                                    </span>
                                    <span className="seperator"> | </span>
                                    <span className="link">
                                        <span className="glyphicon glyphicon-link pr-1" />
                                        <a href="https://www.salesforce.com/" target="_blank" rel="noopener noreferrer">https://www.salesforce.com/</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>Likeli Inc.</h4>
                            <p className="experience-period">
                                Jan 2021 - Mar 2021
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">Internship - Web Developer</strong>
                                <span className="p-2 hidden-phone para">
                                    Created Confluence documents and structure for new Likeli employees and existing ones. Wrote technical documentation of Likeli technical stack, user testing stories for iPhone application, and local development guide for Likeli web application. Redesigned Likeli’s Non-User Portal for new users invited to an event. Created new, cohesive look and created new email reminder functionality on top of old Non-User Portal. Utilized Ruby on Rails, JavaScript, HAML, SCSS, Postman, Github/Gitlab, and PostgreSQL.
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                        <span className="glyphicon glyphicon-map-marker pr-2" />
                                        Remote Santa Clara, CA
                                    </span>
                                    <span className="seperator"> | </span>
                                    <span className="link">
                                        <span className="glyphicon glyphicon-link pr-1" />
                                        <a href="https://likeliapp.com/" target="_blank" rel="noopener noreferrer">https://likeliapp.com/</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>Salesforce</h4>
                            <p className="experience-period">
                                Jun 2020 - Sept 2020
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">Internship - Software Engineer</strong>
                                <span className="p-2 hidden-phone para">
                                    Built Command Line Interface to facilitate the migration of metadata schema and mappings from test to production Salesforce Customer 360 Data Manager Clouds. Presented numerous organization-wide demos and participated in numerous API, CLI, and Database Review Boards. Worked with the Metadata Service team, implementing Salesforce quality code, unit testing, and utilizing Agile development practices. Utilized TypeScript, Chai, Mocha, Sinon, Git, Jenkins, Postman, and authored API Specifications.
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                        <span className="glyphicon glyphicon-map-marker pr-2" />
                                        Remote San Fransisco, CA
                                    </span>
                                    <span className="seperator"> | </span>
                                    <span className="link">
                                        <span className="glyphicon glyphicon-link pr-1" />
                                        <a href="https://www.salesforce.com/" target="_blank" rel="noopener noreferrer">https://www.salesforce.com/</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>Nordstrom</h4>
                            <p className="experience-period">
                                Jun 2019 - Aug 2019
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">Internship - Software Engineer</strong>
                                <span className="p-2 hidden-phone para">
                                    Built full stack web application to service the needs of customer’s data required by the California Consumer Privacy Act. Contributed over 50% of code and to the design/architecture of company wide tool, accessing a wide variety of Nordstrom databases, APIs, and technologies. Utilized AWS Lambdas, S3, DynamoDB, Step Functions, API Gateway, Serverless, ReactJS, Web Sockets, REST/SOAP APIs, Postman, SQL, and Python.
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                        <span className="glyphicon glyphicon-map-marker pr-2" />
                                            Seattle, WA
                                    </span>
                                    <span className="seperator"> | </span>
                                    <span className="link">
                                        <span className="glyphicon glyphicon-link pr-1" />
                                        <a href="http://nordstrom.com/" target="_blank" rel="noopener noreferrer">http://nordstrom.com/</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="experience row mt-5">
                        <div className="col-md-4">
                            <h4>SCU Arts and Sciences Collaboration App</h4>
                            <p className="experience-period">
                                Mar 2019 - Aug 2019
                            </p>
                        </div>
                        <div className="col-md-8">
                            <p>
                                <strong className="p-2 para">Part Time - iOS Developer</strong>
                                <span className="p-2 hidden-phone para">
                                    Selected to help develop an iOS app that will allow students and faculty to share and comment on research and projects. Collaborated with fellow Computer Science students and Humanities faculty. Utilized XCode, Objective-C, and Github.
                                </span>
                                <span className="experience-details p-2 para">
                                    <span className="location">
                                        <span className="glyphicon glyphicon-map-marker pr-2" />
                                            Santa Clara University
                                    </span>
                                    <span className="seperator"> | </span>
                                    <span className="link">
                                        <span className="glyphicon glyphicon-link pr-1" />
                                        <a href="https://www.scu.edu/cas/" target="_blank" rel="noopener noreferrer">https://www.scu.edu/cas/</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <h3>Projects</h3>
                    <div className="experiences">
                        <div className="experience row mt-5">
                            <div className="col-md-4">
                                <h4>Leasr</h4>
                                <p className="experience-period">
                                    Mar 2019 - Jun 2019
                                </p>
                            </div>
                            <div className="col-md-8">
                                <p>
                                    <strong  className="p-2 para">Co-Founder and CEO</strong>
                                    <span  className="p-2 hidden-phone para">
                                        Developing a web application to allow people with a .edu domain create housing/apartment listings and connect with potential sublets at college campuses during a term abroad, Summer internship/job, or term off. Selected to participate in the Bronco Venture Accelerator Prep School, an SCU backed startup incubator with 12 weeks of startup guidance, workshops, speakers, and network building. Utilizing ReactJS, AWS, Google Maps API, and Github.
                                    </span>
                                    <span  className="experience-details p-2 para">
                                        <span className="location">
                                            <span className="glyphicon glyphicon-map-marker pr-2" />
                                            Santa Clara, CA
                                        </span>
                                        <span className="seperator"> | </span>
                                        <span className="link">
                                            <span className="glyphicon glyphicon-link pr-1" />
                                            <a href="https://www.scu.edu/cioccacenter/bronco-ventures/bronco-venture-accelerator/" target="_blank" rel="noopener noreferrer">https://www.scu.edu/cioccacenter/bronco-ventures/bronco-venture-accelerator/</a>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}