import React from "react";
import './Contact.css';
import instagram from '../../assets/icons-instagram.svg';
import linkedin from '../../assets/icons-linkedin.svg';

export default class Contact extends React.Component {
    render() {
        return (
            <div className="contact pt-5" id="contact">
                <a href="https://www.instagram.com/mitchahan/" target="blank" className="m-5"><img src={instagram} alt="Instagram" id="instagram" /></a>
                <a href="https://www.linkedin.com/in/mitchahan/" target="blank" className="m-5"><img src={linkedin} alt="LinkedIn" id="linkedin" /></a>
            </div>
        );
    }
}